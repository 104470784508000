$text-color: #060038;

body {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
}

.Header {
  height: 105vh;
  margin-bottom: 100px;
  background-color: #1f18c0;
  .logo {
    position: absolute;
    left: 12%;
    top: 41px;
    width: 250px;
    object-fit: cover;
    .cls-1 {
      fill: #fff;
    }
  }
  .SideBar {
    position: absolute;
    width: fit-content;
    left: 95%;
    bottom: -5vh;
    .text {
      color: white;
    }
    .line {
      border-color: white;
    }
  }
  .navbar {
    float: right;
    display: flex;
    width: 37%;
    height: 100%;
    background-color: #e82e61;
    background-image: url("./images/background.webp");
    background-size: 100% auto;
    justify-content: space-evenly;

    .link {
      position: relative;
      height: fit-content;
      top: 41px;
      color: white;
      text-decoration: none;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: white;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }
      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
  .title {
    position: absolute;
    left: 12%;
    top: 45%;
    b {
      font-size: 40px;
    }
  }
  .btn {
    margin-top: 30px;
    width: 160px;
    height: 39px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: $text-color;
    text-decoration: none;
    transition: 300ms;
    &:hover {
      cursor: pointer;
      color: white;
      background-color: #e82e61;
    }
  }
  .arrows {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    bottom: 0;
    .arrow {
      position: relative;
      width: 250px;
      height: 250px;
      left: 0;
      margin-left: 50px;
      top: 62%;
    }
  }
  @media screen and (max-width: 900px) {
    .navbar {
      display: none;
    }
    .arrow {
      margin-left: 10px !important;
    }
  }
}

.Missao {
  display: flex;
  flex-direction: column;
  color: $text-color;
  justify-content: center;
  align-items: center;
  h1 {
    margin: 0 12%;
    max-width: 730px;
    font-size: 30px;
    font-weight: bold;
  }
  p {
    margin: 0 12%;
    max-width: 730px;
    margin-top: 50px;
    font-size: 16px;
    font-weight: normal;
  }
  .video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 250px;
    background-color: #1f18c0;
    .player {
      display: block;
      width: 731px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      transform: translateY(-50%);
      border: 0px;
    }
    h1 {
      position: relative;
      top: -90px;
      max-width: 730px;
      color: white;
    }
  }
  .arrows {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 12%;
    width: auto;
    bottom: -18px;

    .arrow {
      width: auto;
      height: 36px;
      margin: 0 7px;
    }
  }
}

.Missao-2 {
  display: flex;
  flex-wrap: wrap;
  margin: 100px 12% 0 12%;
  color: $text-color;
  img {
    height: 360px;
    width: 540px;
    max-width: 100%;
    object-fit: cover;
    margin-right: 5%;
  }
  .text {
    flex: 1;
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 16px;
    }
  }
}

.bigArrows {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 100px;
  width: auto;
  .bigArrow {
    width: auto;
    height: 258px;
    margin: 0 4%;
  }
}

@media screen and (max-width: 900px) {
  .bigArrows {
    display: none;
  }
}

.Footer {
  .container {
    height: 227px;
    padding: 0 12%;
    background-color: #f1f1f1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    .text {
      color: $text-color;
      font-size: 20px;
    }
    .email {
      color: $text-color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        &::after {
          left: 13px;
        }
        cursor: pointer;
      }
      &::after {
        position: relative;
        content: "➤";
        display: inline-block;
        left: 8px;
        color: #e82e61;
        transition: 300ms ease;
      }
    }
    .social {
      margin: 15px 15px;
    }
  }
  .BottomBar {
    padding: 20px 12%;
    background-color: #e82e61;
    font-size: 12px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.SideBar {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 6%;
  @media screen and (max-width: 900px) {
    display: none;
  }
  .line {
    margin-top: 20px;
    height: 100px;
    border-left: solid 2px #060038;
  }
  .text {
    transform: rotate(-90deg);
    font-weight: bold;
    text-orientation: upright;
    font-size: 14px;
    color: $text-color;
  }
}

.Slides {
  margin: 50px 12%;
  max-width: 1200px;
  aspect-ratio: 16/9;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    position: absolute;
    max-width: 1200px;
    width: 100vw;
    // height: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  .btn {
    cursor: pointer;
    width: 5vw;
    height: 5vw;
    min-width: 50px;
    min-height: 50px;
    fill: white;
    position: absolute;
    top: calc(50% - 20px);
    z-index: 2;
  }
  .right {
    right: 0;
  }
  .left {
    left: 0;
    transform: rotate(180deg);
  }
}

.Colaboradores {
  display: flex;
  flex-wrap: wrap;
  margin: 0 12%;
  color: $text-color;
  .text {
    margin: 0 5%;
    h1 {
      font-size: 25px;
    }
    p {
      font-size: 20px;
    }
  }
}

.Equipa {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 0 12% 100px 12%;
  color: $text-color;
  .avatar {
    display: flex;
    max-width: 100%;
    align-items: center;
    flex-direction: column;
    .circle {
      position: relative;
      width: 350px;
      img {
        border-radius: 50%;
        width: 100%;
      }
      .arrow {
        position: absolute;
        right: -10px;
        bottom: -10px;
        width: 140px;
        height: auto;
        fill: #e82e61;
      }
    }
    .controls {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      button {
        margin: 30px 12px;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        cursor: pointer;
        background-color: white;
        border: solid 1px #979797;
        &:hover {
          background-color: #f1f1f1;
        }
        &:disabled {
          cursor: unset;
          background-color: #f1f1f1;
        }
      }
    }
  }
  .text {
    max-width: 540px;
    h1 {
      font-size: 24px;
      font-weight: normal;
    }
    p {
      font-size: 16px;
    }
  }
}

.Curso {
  color: $text-color;
  margin: 0 12%;
  h1 {
    font-size: 30px;
    font-weight: bold;
    max-width: 445px;
  }
  p {
    max-width: 445px;
    height: 243px;
    margin-top: 25px;
    font-size: 16px;
    font-weight: normal;
  }
  .Accordion {
    font-weight: bold;
    font-size: 30px;
    overflow: hidden;

    .tab {
      width: 100%;
      color: $text-color;
      overflow: hidden;
      &-label {
        display: flex;
        text-decoration: none;
        justify-content: space-between;
        padding: 27px 0;
        font-weight: bold;
        cursor: pointer;
        transition: ease 0.5s;

        &::after {
          color: #e82e61;
          content: "+";
          width: 1em;
          height: 1em;
          text-align: center;
          transition: all 0.35s;
        }
      }
      &-content {
        max-width: 100%;
        width: 730px;
        font-size: 16px;
        font-weight: normal;
        max-height: 0;
        background: white;
        transition: all 350ms ease;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
    // :checked
    input:checked {
      + .tab-label {
        &::after {
          content: "-";
        }
      }
      ~ .tab-content {
        max-height: 100vh;
        padding: 23px 0 50px 0;
        animation: fadeIn 300ms linear;
      }
    }
  }
}
.divider {
  border: solid 1px #979797;
}

.image {
  width: 100%;
  height: 809px;
  object-fit: cover;
  margin: 100px 0;
}

.Logos {
  display: flex;
  flex-wrap: wrap;
  margin: 75px 12%;
  justify-content: space-evenly;
  align-items: center;
  max-width: 100%;
  img {
    max-height: 50px;
    margin: 15px 20px;
    object-fit: contain;
  }
}
